<template>
  <div class="ocr">
    <div class="proj_box">
      <div class="proj_title">参与项目</div>
      <div class="proj_name">{{ proj_name }}</div>
    </div>
    <div class="uploadTitle">
      <span>上传证件照片</span>
      <span v-if="showZjtype" class="selectpan" @click="showpopup = true">
        {{ selectName }}
        <div :class="{ active: showpopup }"></div>
      </span>
      <van-popup v-model="showpopup" round position="bottom" :style="{ height: '55%' }">
        <van-picker title="标题" show-toolbar :columns="selectOptions" @confirm="onConfirmpopup" @cancel="showpopup = false" />
      </van-popup>
    </div>
    <div class="uploadStandard">每次最多上传0/2张，单张文件不超过5MB</div>
    <div class="ocrBox">
      <template v-if="isWeixin">
        <div class="upload-img" @click="chooseImage('front')">
          <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
        </div>
        <div class="upload-img" @click="chooseImage('back')">
          <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
        </div>
      </template>
      <template v-else>
        <ImageUploader class="upload-img" v-model="img_front" single @change="uploadFront">
          <template slot="content">
            <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
          </template>
        </ImageUploader>
        <ImageUploader class="upload-img" v-model="img_back" single @change="uploadBack">
          <template slot="content">
            <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
          </template>
        </ImageUploader>
      </template>
    </div>
    <div class="standard">上传标准</div>
    <div class="uploadMark">证件必须是清晰彩色原件电子版本。可以是扫描件或是数码拍摄照片。仅支持jpg，png，jpeg的图片格式。</div>
    <div class="standardImg">
      <img src="/static/uploadExp.png" />
    </div>
    <div class="identity">身份信息</div>
    <template v-if="selectedId === 1">
      <van-cell-group class="information">
        <van-cell title="姓名" :value="form.name" />
        <van-cell title="身份证号" :value="form.idsn" />
      </van-cell-group>
    </template>
    <template v-else>
      <van-form class="information">
        <van-field label="姓名" v-model="form.name" placeholder="请填写姓名" />
        <van-field label="证件号" v-model="form.idsn" placeholder="请填写证件号" />
        <van-field label="有效期" v-model="form.expire_date" @click.prevent="showpicker = true" @focus.prevent="preventKey" placeholder="请选择有效期" />
        <van-calendar v-model="showpicker" type="range" title="选择时间区间" @confirm="onConfirm" :minDate="minDate" :maxDate="new Date(2100, 0, 1)" color="#4B99FF" />
      </van-form>
    </template>

    <div class="tips">
      <span v-if="selectedId === 1">信息自动识别，无法识别请重新上传图片</span>
      <span v-else></span>
    </div>
    <div class="btn">
      <van-button v-if="selectedId === 1" round type="info" size="large" class="up" :disabled="disabled" @click="next">提交实名认证</van-button>
      <van-button v-else round type="info" size="large" class="up" :disabled="disabled" @click="linkToBankcard">下一步</van-button>
    </div>
    <div class="goBack" @click="goback">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { Ocr } from '@/components/ocr.js'
import ImageUploader from '@/components/ImageUploader'
import { Dialog, Toast } from 'vant'
import { idcardOcr, verifyIdcard, register, login, getRegisterType, getRegisterFirst } from '@/api/api'
import { mapState, mapActions } from 'vuex'
export default {
  name: '',
  components: {
    ImageUploader
  },
  data() {
    return {
      disabled: false,
      form: {
        name: '',
        idsn: '',
        expire_date: ''
      },
      idcard_front: '/static/idcard_front.png',
      idcard_back: '/static/idcard_back.png',
      img_front: [], // 非微信环境上传身份证正面
      img_back: [], // 非微信环境上传身份证反面
      selectedId: 1,
      vall: 0,
      showpopup: false,
      showpicker: false,
      currentdate: new Date(),
      selectOptions: [],
      showZjtype: false,
      selectName: '选择其他证件类型',
      minDate: new Date(2000, 0, 0)
    }
  },
  mixins: [Ocr],
  computed: {
    ...mapState(['userId', 'registerInfo', 'proj_name', 'otherDoctorInfo', 'utm_source'])
  },
  mounted() {
    console.log(this.userId, this.registerInfo, this.otherDoctorInfo, '------')
    this.initgetRegisterType()
  },
  methods: {
    ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO']),

    async ocr() {
      if (this.selectedId !== 1) {
        //非身份证照片不读取照片信息
        return
      }
      try {
        let res = await idcardOcr({
          idCards: this.idcards,
          projectId: localStorage.getItem('project_id')
        })
        this.form.idsn = res.idsn
        this.form.name = res.name
      } catch (err) {
        Toast(err.response.data.message)
      }
    },
    preventKey() {
      document.activeElement.blur()
    },
    async next() {
      if (!this.idcards[0].image || !this.idcards[1].image) {
        Toast('请上传证件照片')
        return false
      }
      // await verifyIdcard({
      //     doctor_id: this.userId,
      //     name: this.name,
      //     idsn: this.idsn
      // }).then(res => {
      // }).catch(err => {
      //     Toast(err.response.data.message)
      // })
      let data = {
        mobile: this.$route.query.mobile,
        code: this.$route.query.code,
        password: this.$route.query.password,
        rePassword: this.$route.query.rePassword,
        projectId: localStorage.getItem('project_id'),
        name: this.form.name,
        idcard: this.form.idsn,
        images: [this.idcards[0].image, this.idcards[1].image]
      }
      await register(data)
        .then(() => {
          Toast({
            duration: 1500, // 持续展示 toast
            forbidClick: true,
            message: '实名认证成功'
          })
          login({
            mobile: this.$route.query.mobile,
            code: this.$route.query.code,
            projectId: localStorage.getItem('project_id')
          })
            .then(res => {
              console.log('res', res)
              this.SET_USER_ID(res.id)
              this.SET_DOCTOR_INFO(res)
              this.$router.replace({
                path: '/me'
              })
            })
            .catch(err => {
              Toast(err.response.data.message)
            })
        })
        .catch(err => {
          Dialog.alert({
            title: '温馨提示',
            message: err.response.data.message,
            confirmButtonColor: '#4B99FF',
            confirmButtonText: '我知道了'
          })
          // Toast(err.response.data.message)
        })
    },
    //获取注册类型
    async initgetRegisterType() {
      if (this.otherDoctorInfo.id_type === '身份证') {
        this.selectName = this.otherDoctorInfo.id_type
      }
      if (this.otherDoctorInfo && this.otherDoctorInfo.id_type === this.selectName) {
        if (this.otherDoctorInfo.idsn_imgs) {
          if (this.otherDoctorInfo.idsn_imgs[0]) {
            this.idcard_front = this.otherDoctorInfo.idsn_imgs[0]
          } else {
            this.idcard_front = '/static/idcard_' + i + '.png'
          }
          if (this.otherDoctorInfo.idsn_imgs[1]) {
            this.idcard_back = this.otherDoctorInfo.idsn_imgs[1]
          } else {
            this.idcard_back = '/static/idcard_' + i + '.png'
          }
        }
        for (let key in this.form) {
          this.form[key] = ''
          if (this.otherDoctorInfo[key]) {
            this.form[key] = this.otherDoctorInfo[key]
          }
        }
      }
      await getRegisterType({ params: { projectId: localStorage.getItem('project_id') } }).then(res => {
        if (Object.getOwnPropertyNames(res).length !== 0) {
          this.showZjtype = true
          for (let key in res) {
            this.selectOptions.push(res[key])
          }
        } else {
          this.showZjtype = false
        }
      })
    },

    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      this.showpicker = false
      this.form.expire_date = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    onConfirmpopup(val, i) {
      this.selectedId = i + 1
      this.selectName = val
      this.showpopup = false
      if (this.otherDoctorInfo && val === this.otherDoctorInfo.id_type) {
        if (this.otherDoctorInfo.idsn_imgs) {
          this.idcard_front = this.otherDoctorInfo.idsn_imgs[0] ? this.otherDoctorInfo.idsn_imgs[0] : '/static/idcard_' + i + '.png'
          this.idcard_back = this.otherDoctorInfo.idsn_imgs[1] ? this.otherDoctorInfo.idsn_imgs[1] : '/static/idcard_' + i + '.png'
        }
        for (let key in this.form) {
          this.form[key] = ''
          if (this.otherDoctorInfo[key]) {
            this.form[key] = this.otherDoctorInfo[key]
          }
        }
      } else {
        for (let key in this.form) {
          this.form[key] = ''
        }
        this.idcard_front = '/static/idcard_' + i + '.png'
        this.idcard_back = '/static/idcard_' + i + '.png'
        if (this.selectedId == 1) {
          this.idcard_front = '/static/idcard_front.png'
          this.idcard_back = '/static/idcard_back.png'
        }
      }
      console.log(val, i, 'valllll')
    },
    linkToBankcard() {
      if (!this.idcard_front || !this.idcard_back) {
        Toast('请上传证件照片')
        return false
      }
      if (this.form.name === '') {
        Toast('姓名不能为空')
        return false
      }
      if (this.form.idsn === '') {
        Toast('证件号不能为空')
        return false
      }
      if (this.form.expire_date === '') {
        Toast('有效期不能为空')
        return false
      }
      let flag = true
      switch (this.selectedId) {
        //军官证  军/兵/士/文/职/广/（其他中文） + "字第" + 4到8位字母或数字 + "号"
        case 2:
          flag = /^[\u4e00-\u9fa5](字第)([0-9a-zA-Z]{4,8})(号)$/.test(this.form.idsn)
          break
        //港澳居民来往内地通行证  H/M + 10位或8位数字··
        case 3:
          flag = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/.test(this.form.idsn)
          break
        //台湾居民来往大陆通行证  新版8位或18位数字,旧版10位数字 + 英文字母
        case 4:
          flag = /^[0-9]{8}$/.test(this.form.idsn) || /^[0-9]{18}$/.test(this.form.idsn) || /^[a-zA-Z0-9]{10}$/.test(this.form.idsn)
          break
      }
      if (!flag) {
        Toast('证件号填写有误，请仔细检查')
        return false
      }
      let data = {
        mobile: this.$route.query.mobile,
        code: this.$route.query.code,
        password: this.$route.query.password,
        rePassword: this.$route.query.rePassword,
        projectId: localStorage.getItem('project_id'),
        name: this.form.name,
        idcard: this.form.idsn,
        id_type: this.selectName,
        expire_date: this.form.expire_date,
        images: [this.idcard_front, this.idcard_back]
        // images: ['/upload/export/202211/68187-胡平平/身份证正反面.jpg', '/upload/export/202211/68187-胡平平/身份证正反面.jpg']
      }
      // console.log(data, 'getRegisterFirst')
      this.$store.commit('setOtherDocRgflag', true)
      getRegisterFirst(data)
        .then(res => {
          console.log(res, 'getRegisterFirst')
          this.$router.replace({
            path: 'bankcard',
            query: {
              doctorId: res.id,
              name: res.name,
              mobile: res.mobile
            }
          })
        })
        .catch(err => {
          Toast(err.response.data.message)
        })
    },
    goback() {
      let query = {}
      if (!['iga_baidu'].includes(this.utm_source)) query.project_id = localStorage.getItem('project_id')
      this.$store.commit('setOtherDocRgflag', true)
      this.$router.replace({
        path: 'login',
        query: query
      })
    },
    uploadFront() {
      const len = this.img_front.length
      if (len > 0) {
        this.idcard_front = this.img_front[0]
        this.idcards[1].image = this.img_front[0]
        this.fileUploadToOcr()
      } else {
        this.idcard_front = '/static/bankcard_front.png'
        this.idcards[1].image = ''
      }
    },
    uploadBack() {
      const len = this.img_back.length
      if (len > 0) {
        this.idcard_back = this.img_back[0]
        this.idcards[0].image = this.img_back[0]
        this.fileUploadToOcr()
      } else {
        this.idcard_back = '/static/bankcard_front.png'
        this.idcards[0].image = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ocr {
  height: 100%;
  width: 100%;
  // padding: 0px 11px 0 9px;

  box-sizing: border-box;
  text-align: left;

  .proj_box {
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 22px;

    .proj_title {
      font-size: 13px;
      height: 25px;
      background: #e2eeff;
      color: #4b99ff;
      text-align: center;
      line-height: 25px;
      border-radius: 3px;
      padding: 0 3px;
      font-weight: bold;
    }

    .proj_name {
      font-size: 15px;
      color: #292929;
      margin-left: 14px;
    }
  }

  .standard,
  .identity,
  .uploadTitle {
    font-size: 15px;
    color: #232323;
    margin-bottom: 13px;
    margin-left: 16px;
    margin-right: 16px;
    font-family: PingFangSC-Medium;
  }

  .uploadTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectpan {
      color: #4b99ff;
      font-weight: 500;
      font-size: 12px;
      display: flex;

      div {
        width: 0px;
        height: 0px;
        border-top: 10px solid #4b99ff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 10px solid transparent;
        margin-top: 4px;
        margin-left: 4px;
        margin-bottom: -6px;
        transition: 0.3s linear;
      }

      .active {
        transform: rotate(180deg);
        transition: 0.3s linear;
        margin-top: -6px;
      }
    }
  }

  .uploadStandard,
  .uploadMark {
    // color: #A8A8A8;
    color: #565656;
    font-size: 12px;
    margin-bottom: 3px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .ocrBox {
    height: 128px;
    width: 100%;
    display: flex;
    justify-content: center;

    .upload-img {
      // height: 94px;
      // width: 139px;
      height: 96px;
      width: 165px;
      background-position: left top, right top, right bottom, left bottom;
      background-repeat: no-repeat;
      background-size: 16px;
      // padding: 20px;
      padding: 15px;

      .upload-img-a,
      .upload-img-b {
        width: 100%;
        height: 0;
        padding-top: 63%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
        border-radius: 5px;
      }
    }
  }

  .uploadMark {
    margin-bottom: 26px;
  }

  .standardImg {
    width: 100%;
    height: 90px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .information {
    margin-bottom: 10px;
  }

  .tips {
    // color: #A8A8A8;
    color: #565656;
    font-size: 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>
